<template>
  <div class="form-one">
    <div class="form-one-title">
      您的意见建议对我们改进本线上平台的内容，更好的服务于打击和预防野生动物犯罪，保护生物多样性，保护人类福祉的生态屏障，捍卫生态安全至关重要，感谢您的反馈，让我们携手共创人与自然和谐共生的美丽中国。
    </div>
    <div class="form-one-form">
      <div class="form-item">
        <div class="form-item-left">
          <i class="icon-diamond"></i>
          <span class="must">需求</span>
        </div>
        <div class="form-item-right">
          <el-select v-model="form.traindemand" placeholder=" ">
            <el-option :value="'反馈建议'" label="反馈建议">反馈建议</el-option>
            <el-option :value="'预约执法培训'" label="预约执法培训"
              >预约执法培训</el-option
            >
            <el-option :value="'其它'" label="其它">其它</el-option>
          </el-select>
        </div>
      </div>
      <div class="form-item">
        <div class="form-item-left">
          <i class="icon-diamond"></i>
          <span class="must">您所在省市</span>
        </div>
        <div class="form-item-right">
          <el-select
            v-model="form.province"
            placeholder="省"
            @change="changeProvince"
          >
            <el-option
              v-for="item in areaList"
              :key="item.code"
              :label="item.name"
              :value="item.code"
            >
            </el-option>
          </el-select>
          <el-select class="input-extra" v-model="form.city" placeholder="市">
            <el-option
              v-for="item in cityList"
              :key="item.code"
              :label="item.name"
              :value="item.code"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="form-item">
        <div class="form-item-left">
          <i class="icon-diamond"></i>
          <span>部门属性</span>
        </div>
        <div class="form-item-right">
          <el-select v-model="form.department" placeholder=" ">
            <el-option :value="'林业政府部门'" label="林业政府部门"
              >林业政府部门</el-option
            >
            <el-option :value="'公安'" label="公安">公安</el-option>
            <el-option :value="'海关（缉私）'" label="海关（缉私）"
              >海关（缉私）</el-option
            >
            <el-option :value="'打私'" label="打私">打私</el-option>
            <el-option :value="'市场监督'" label="市场监督">市场监督</el-option>
            <el-option :value="'野生动物救助'" label="野生动物救助"
              >野生动物救助</el-option
            >
            <el-option :value="'非政府组织'" label="非政府组织"
              >非政府组织</el-option
            >
            <el-option :value="'其它'" label="其它">其它</el-option>
          </el-select>
        </div>
      </div>
      <div class="form-item">
        <div class="form-item-left">
          <i class="icon-diamond"></i>
          <span>单位名称</span>
        </div>
        <div class="form-item-right">
          <el-input v-model.trim="form.company" placeholder=" " />
        </div>
      </div>
      <div class="form-item">
        <div class="form-item-left">
          <i class="icon-diamond"></i>
          <span>称呼</span>
        </div>
        <div class="form-item-right">
          <el-input v-model.trim="form.name" placeholder=" " />
        </div>
      </div>
      <div class="form-item">
        <div class="form-item-left">
          <i class="icon-diamond"></i>
          <span class="must">联系方式</span>
        </div>
        <div class="form-item-right">
          <el-input v-model.trim="form.phone" placeholder=" " />
        </div>
      </div>
      <div class="form-item">
        <div class="form-item-left">
          <i class="icon-diamond"></i>
          <span>留言</span>
        </div>
        <div class="form-item-right">
          <el-input v-model.trim="form.mes" type="textarea" placeholder="请输入留言" :autosize="{ minRows: 3, maxRows: 8 }" resize="none" />
        </div>
      </div>
      <div class="form-item">
        <el-button
          type="primary"
          class="submit"
          @click="submit"
          :loading="submitLoading"
          >点击提交</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { addInformation } from "@/api/base.js";

export default {
  name: "FormOne",
  data() {
    return {
      areaList: [],
      submitLoading: false, // 提交loading
      form: {
        traindemand: "", // 需求
        province: "", // 省
        city: "", // 市
        department: "", // 部门属性
        company: "", // 单位名称
        name: "", // 称呼
        phone: "", // 联系方式
        mes:'', // 留言
      },
    };
  },
  computed: {
    cityList() {
      if (this.form.province) {
        let item = this.areaList.find((el) => el.code === this.form.province);
        return item ? item.children : [];
      } else {
        return [];
      }
    },
  },
  created() {
    this.areaList = require("@/assets/area.json").data;
  },
  methods: {
    // 提交
    submit() {
      const { traindemand, province, city, department, company, name, phone, mes } = this.form;
      if (
        !traindemand ||
        !province ||
        (this.cityList.length > 0 && !city) ||
        // !department ||
        // !company ||
        // !name ||
        !phone
      ) {
        this.$message({
          type: "warning",
          message: "请填写必填信息内容",
        });
        return;
      }
      let data = this.form;
      data["type"] = 1;

      let item = this.areaList.find((el) => el.code === province);
      let provinceStr = item.name;
      let childItem = item.children.find((el) => el.code === city);
      let cityStr = childItem ? childItem.name : "";

      data["province"] = provinceStr;
      data["city"] = cityStr;

      console.log(department,company,name,mes)

      this.submitLoading = true;
      addInformation(data).then((res) => {
          if (res.code === 200) {
            this.$message.success("提交成功！");
            this.form = {
              traindemand: "", // 需求
              province: "", // 省
              city: "", // 市
              department: "", // 部门属性
              company: "", // 单位名称
              name: "", // 称呼
              phone: "", // 联系方式
            };
          } else {
            this.$message.warning(res.errMessage);
          }
        })
        .finally(() => {
          this.submitLoading = false;
        });
    },
    // 删除空白项
    removeEmpty(obj) {
      for (const key in obj) {
        if (!obj[key]) {
          delete obj[key];
        }
      }
      return obj;
    },
    // 重置内容
    resetData() {
      this.form = {
        type: 2, // 留言
        address: "", // 来自
        phone: "", // 电话
        mes: "", // 内容
      };
    },
    // 更改省
    changeProvince() {
      this.form.city = "";
    },
  },
};
</script>

<style lang="scss" scoped>
@media only screen and (min-width: 769px) {
  .form-one {
    max-height: calc(100% - 46px) !important;
    .form-one-title {
      font-size: 22px;
    }
    .form-item-left {
      font-size: 14px;
      width: 30%;
    }
    .form-item-right {
      width: 70%;
      display: flex;

      .el-input {
        width: 40%;
        margin-right: 4%;
      }
      .el-select {
        width: 40%;
        margin-right: 4%;
      }
      .el-button {
      }
    }
    .form-item {
      .el-button.submit {
        width: 50%;
        margin: 16px auto;
      }
    }
  }
}
@media only screen and (max-width: 768px) {
  .form-one {
    max-height: 100% !important;
    padding-bottom: 10px;
    .form-one-title {
      font-size: 20px;
    }
    .form-item-left {
      font-size: 12px;
      width: 36%;
    }
    .form-item-right {
      width: 64%;
      .el-input {
      }
      .el-select {
        width: 50%;
      }
      .el-button {
      }
      &.code {
        .el-input {
          width: 50%;
          margin-right: 4%;
        }
        .el-button {
          width: 46%;
        }
      }
    }
    .form-item {
      .el-button.submit {
        width: 100%;
        margin-top: 16px;
        margin-bottom: 16px;
      }
    }
  }
}
.form-one {
  .form-one-title {
    font-size: 14px;
    line-height: 2;
    text-indent: 2em;
    padding: 16px 0 22px;
    border-bottom: 1px solid #5da8f3;
  }
  .form-one-form {
    margin-top: 22px;
    .form-item {
      display: flex;
      align-items: center;
      margin-top: 2%;

      .el-buttom.submit {
      }
    }

    .form-item-left {
      .icon-diamond {
        display: inline-block;
        width: 8px;
        height: 8px;
        background-color: #5da8f3;
        transform: rotate(45deg);
      }
      span {
        margin-left: 4%;
        &.must {
          &::before {
            content: "*";
            color: red;
            margin-right: 4px;
          }
        }
      }
    }
    .form-item-right {
      .el-input {
      }
      .el-select {
      }
      .el-button {
      }
    }
  }
}
</style>